<script lang="ts">
import { h } from "vue";

export default defineComponent({
  functional: true,
  setup() {
    const { itemKey } = useInjectionKeys();
    const item = inject(itemKey);
    let region;
    if (item?.attributes?.region?.data) region = item?.attributes?.region?.data;
    else if (item?.attributes?.places?.data[0]?.attributes?.region?.data)
      region = item?.attributes?.places?.data[0]?.attributes?.region?.data;
    else region = null;

    log(`Robots Region:`, region);

    const isCurrentRegion = useIsCurrentRegion(region?.id);

    const robots = computed(() => {
      return region && !isCurrentRegion.value
        ? "noindex, nofollow"
        : "index, follow";
    });

    useHead(() => {
      return {
        meta: [
          {
            name: "robots",
            content: robots.value,
          },
        ],
      };
    });
  },
  render() {
    return h("div", null);
  },
});
</script>
